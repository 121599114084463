import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import LetterAnimation from "../animations/LetterAnimation"
import animationData from "../animations/DESKTOP_MOBILE/01-Intro.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/01-Intro_MOBILE.json"
import useIsTablet from "../hooks/useIsTablet"
//Initial state

//Grid
//|
//v
//Grid item
//|
//v
//Grid item wrapper
//|
//v

const IndexPage = ({ location }) => {
  const isTablet = useIsTablet()

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        location={location.pathname}
      >
        {isTablet ? (
          <React.Fragment>
            <h1 className="hero_title landing_title">
              Landsec: contribution report
            </h1>
          </React.Fragment>
        ) : (
          <LetterAnimation duration={0.7} delay={0.017} initDelay={0.2}>
            <h1 className="hero_title text-animated landing_title">
              <span className="line-inner">Landsec:</span>
            </h1>
            <h1 className="hero_title text-animated landing_title">
              <span className="line-inner">contribution report</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>
    </Layout>
  )
}

export default IndexPage
